import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { getEmpresas } from './EmpresaF';

import {
  Button,
  Tooltip,
  Icon,
  Table,
  Select,
  Input,
  notification,
  Modal,
  Checkbox,
  message
} from 'antd';

import PageLayout from '../../components/layout/PageLayout';
import EmpresaCRUD from '../../components/cruds/empresas/EmpresaCRUD';
import clienteDB from '../../dataManager/dtmCliente';
import mensagem from '../../components/messages/message';
import listObj from '../../components/listSearch/listSearch';

import './Empresa.css';
import 'antd/dist/antd.css';


const { Search } = Input;
const { Option } = Select;
let interval;


class Empresa extends Component {
  state = {
    loading: false,
    isSearching: false,
    blockLoadMore: false,
    gestorId: undefined,
    empresas: [],
    empresasOriginal: [],
    checked: false,
    columns: [
      {
        title: 'CNPJ',
        dataIndex: 'cnpj',
        key: 'cnpj',
        sorter: true,
        sortDirections: ["descend", "ascend"],
        editable: false
      },
      {
        title: 'Razão Social',
        dataIndex: 'razaoSocial',
        key: 'razaoSocial',
        editable: false,
        sorter: true,
        sortDirections: ["descend", "ascend"],
        editable: false
      },
      {
        title: 'Nome Fantasia',
        dataIndex: 'nomeFantasia',
        key: 'nomeFantasia',
        sorter: true,
        sortDirections: ["descend", "ascend"],
        editable: false
      },
      {
        title: 'Login',
        dataIndex: 'login.login',
        key: 'login',
        sorter: true,
        sortDirections: ["descend", "ascend"],
        editable: false
      },
      {
        title: 'E-mail',
        dataIndex: 'email',
        key: 'email',
        sorter: true,
        sortDirections: ["descend", "ascend"],
        editable: false
      },
      {
        title: 'Telefone',
        dataIndex: 'endereco.telefone',
        key: 'telefone',
        sorter: true,
        sortDirections: ["descend", "ascend"],
        editable: false
      },
      {
        title: 'Chave API',
        dataIndex: 'integracao.key',
        key: 'api',
        sorter: true,
        sortDirections: ["descend", "ascend"],
        editable: false
      },
      {
        title: "Ativo",
        dataIndex: "ativoStr",
        key: "ativoStr",
        sorter: true,
        sortDirections: ["descend", "ascend"],
        editable: false
      },
      {
        title: '',
        width: 'auto',
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip placement='topLeft' title='Editar Cadastro'>
              <a onClick={() => this.editarEmpresaElement(record)}>
                <Icon type='edit' />
              </a>
            </Tooltip>
          </span >
        ),
      },
    ]
  }

  constructor(props) {
    super(props);

    this.headerEmpresaElement = React.createRef();

    this.applyFilter = this.applyFilter.bind(this);
    this.updateList = this.updateList.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.editarEmpresaElement = this.editarEmpresaElement.bind(this);
    this.getCheckboxState = this.getCheckboxState.bind(this);
    this.checkboxUpdate = this.checkboxUpdate.bind(this);
  }
  
  getCheckboxState(checked) {
    this.setState({ checked, loading: true});
    setTimeout(() => {
      this.checkboxUpdate();      
    }, 1500);    
  }

  async checkboxUpdate() {
    this.applyFilter('');
  }

  async applyFilter(text) {
    this.setState({ loading: true });


    let { empresasOriginal } = this.state;

    if (!this.state.checked) {
      empresasOriginal = empresasOriginal.filter(item => item.ativo);
    }

    if (text === '') {
      this.setState({
        empresas: empresasOriginal,
        isSearching: false,
        loading: false
      });

      return;
    }

    const keys = [
      'cnpj',
      'razaoSocial',
      'nomeFantasia',
      'login.login',
      'email',
      'endereco.telefone',
      'integracao.key',
    ];

    const tableResult = listObj.search(empresasOriginal, text, keys);

    this.setState({
      empresas: tableResult,
      isSearching: true,
      loading: false
    });

    if (tableResult.length === 0) {
      return {
        message: 'Resultado não Encontrado',
        description: 'Sua busca não retornou nenhum resultado. Por favor, redefine sua busca.'
      };
    } else {
      return {
        message: 'Busca finalizada',
        description: 'Caso não encontre a empresa desejada, por favor, refine sua busca.'
      };
    }
  }

  updateList(record) {
    const { empresas } = this.state;

    let registroEncontrado = false;

    this.state.empresas.forEach((item, index) => {
      if (item.key === record.key) {
        empresas[index] = record;
        registroEncontrado = true;
      }
    });

    if (!registroEncontrado) {
      empresas.push(record);
    }

    this.setState({
      empresas,
      empresasOriginal: empresas
    });
  }

  async loadMore() {
    this.setState({ loading: true });

    const {
      empresas,
      gestorId
    } = this.state;

    const lastDocId = empresas[empresas.length - 1].key;
    const data = await getEmpresas(gestorId, lastDocId);

    if (data.length === 0) {
      mensagem.avisar('Não existe mais empresas a serem carregadas.');
      this.setState({ loading: false, blockLoadMore: true });
      return;
    }

    const newTableData = empresas.concat(data);

    this.setState({
      loading: false,
      empresas: newTableData,
      empresasOriginal: newTableData
    });
  }

  async onSelectChange(value) {
    this.setState({ loading: true });

    const empresas = await getEmpresas(value);

    const empresasAtivas = empresas.filter(item => item.ativo);

    this.setState({
      empresas: empresasAtivas,
      empresasOriginal: empresas,
      gestorId: value,
      loading: false,
      isSearching: false,
      blockLoadMore: false
    });
  }

  editarEmpresaElement(record) {
    this.headerEmpresaElement.current.editarEmpresa(record);
  }

  onTableHandleChange = (pagination, filters, sorter) => {

    const columnSortMap = {
      cnpj: 'cnpj',
      razaoSocial: 'razaoSocial',
      nomeFantasia: 'nomeFantasia',
      login: 'login.login',
      email: 'email',
      telefone: 'endereco.telefone',
      api: 'integracao.key',
      ativoStr: 'ativoStr'
    };
  
    const tableData = this.state.empresas.slice();
    const { columnKey, order } = sorter;
    if (columnKey) {
      const field = columnSortMap[columnKey];
      const sortOrder = order === 'descend' ? -1 : 1;
  
      try {
        tableData.sort((a, b) => {
          const aValue = field.split('.').reduce((o, i) => o?.[i], a);
          const bValue = field.split('.').reduce((o, i) => o?.[i], b);
          
          if (aValue === undefined || bValue === undefined) {
            throw new Error(`Erro de valor indefinido ao order pela coluna ${columnKey}`);
          }
  
          return aValue.localeCompare(bValue, undefined, { numeric: true }) * sortOrder;
        });
      } catch (error) {
        message.error(`Erro ao ordenar pela coluna ${columnKey}: ${error.message}`);
        return;
      }
    }
    console.clear();

    // imprimo apenas as chaves de integração da lista ordenada
    tableData.forEach(item => {
      console.log(`${item.integracao.key ? item.integracao.key : item.nomeFantasia}`);
    });
    
    this.setState({ empresas: tableData });
  }
  

  render() {
    return (
      <PageLayout selectItem={'empresas'}>
        <Table
          title={() => (
            <HeaderEmpresa
              ref={this.headerEmpresaElement}
              applyFilter={this.applyFilter}
              updateList={this.updateList}
              onSelectChange={this.onSelectChange}
              getCheckboxState={this.getCheckboxState}
            />
          )}

          footer={() => (
            <div className='footerContainer'>
              <Button
                type='primary'
                loading={this.state.loading}

                disabled={
                  this.state.blockLoadMore ||
                  this.state.isSearching ||
                  !this.state.gestorId
                }

                onClick={this.loadMore}
              >
                Carregar mais Empresas
              </Button>

              <div style={{ marginTop: '0.625rem' }}>
                Quantidade de Empresas: {this.state.empresas.length}
              </div>
            </div>
          )}

          dataSource={this.state.empresas}
          columns={this.state.columns}
          loading={this.state.loading}
          pagination={false}
          onChange={this.onTableHandleChange}          
        />
      </PageLayout>
    )
  }
}

class HeaderEmpresa extends Component {
  state = {
    visible: false,
    gestorId: undefined,
    options: [],
    checked: false
  }

  constructor(props) {
    super(props);

    this.novaEmpresaElement = React.createRef();

    this.loadOptions = this.loadOptions.bind(this);
    this.novaEmpresa = this.novaEmpresa.bind(this);
    this.editarEmpresa = this.editarEmpresa.bind(this);
    this.filterTable = this.filterTable.bind(this);
    this.resetTime = this.resetTime.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onCheck = this.onCheck.bind(this);
  }

  componentDidMount() {
    this.loadOptions();
  }

  async loadOptions() {
    const clientes = await clienteDB.get(true);
    const options = [];

    for (let i = 0; i < clientes.length; i++) {
      const {
        key,
        nomeFantasia,
        email
      } = clientes[i];

      options.push(
        <Option key={i} value={key}>
          {`${nomeFantasia} | ${email}`}
        </Option>
      );
    }

    this.setState({ options });
  }

  novaEmpresa() {
    const { gestorId } = this.state;
    const record = { gestorId };

    this.props.applyFilter('');
    this.novaEmpresaElement.current.show(false, record);
  }

  editarEmpresa(record) {
    this.props.applyFilter('');
    this.novaEmpresaElement.current.show(true, record);
  }

  async filterTable(obj) {
    clearInterval(interval);

    const text = typeof obj === 'object' ? obj.target.value : obj;

    const searchWarning = await this.props.applyFilter(text);

    if (searchWarning) {
      notification.open(searchWarning);
    }
  }

  async resetTime(obj) {
    clearInterval(interval);
    interval = setInterval(this.filterTable, 800, obj.target.value);
  }

  async onSelectChange(value) {
    this.setState({ gestorId: value });
    this.props.onSelectChange(value);
  }

  async onCheck(e) {
    this.setState({ checked: e.target.checked });
    this.props.getCheckboxState(e.target.checked);
  }

  render() {
    return (
      <div className='headerEmpresa'>
        <NovaEmpresa
          ref={this.novaEmpresaElement}
          updateList={this.props.updateList}
        />

        <div className='selectCliente'>
          <label>
            Cliente:
          </label>

          <Select
            showSearch
            placeholder='Selecionar o cliente'
            optionFilterProp='children'

            value={this.state.gestorId}
            onChange={this.onSelectChange}

            style={{ marginLeft: '0.625rem', width: '1200px' }}
          >
            {this.state.options}
          </Select>
        </div>

        <Button
          type='primary'

          onClick={this.novaEmpresa}
          disabled={!this.state.gestorId}

          style={{ marginLeft: '0.625rem', width: '200px'}}
        >
          <Icon className='icon' type='plus' />
          Nova Empresa
        </Button>

        <Search
          allowClear
          placeholder='Procurar Empresa'

          onSearch={this.filterTable}
          onChange={this.resetTime}
          disabled={!this.state.gestorId}

          style={{ marginLeft: '0.625rem', width: '15%' }}
        />

        &nbsp;&nbsp;

        <Checkbox checked={this.state.checked} onChange={this.onCheck}>
          Listar Inativos
        </Checkbox>


      </div>
    )
  }
}

class NovaEmpresa extends Component {
  state = {
    record: [],
    visible: false,
    confirmLoading: false,
    editMode: false
  }

  constructor(props) {
    super(props);

    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      editMode,
      record,
      visible: true,
      confirmLoading: false,
    });
  }

  handleOk(record) {
    this.props.updateList(record);
    this.setState({ visible: false });
  }

  async handleCancel() {
    if (!(await mensagem.confirmar('Cancelar alterações?'))) return;
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        title='Empresa'

        destroyOnClose={true}
        centered={true}
        footer={null}
        closable={false}
        visible={this.state.visible}
        confirmLoading={this.state.confirmLoading}

        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <EmpresaCRUD
          editMode={this.state.editMode}
          record={this.state.record}
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
        />
      </Modal>
    );
  }
}

export default withRouter(Empresa);
